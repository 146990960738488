import {
  AfterContentInit,
  Directive,
  ElementRef,
  OnDestroy,
  OnInit,
  Input,
  NgZone
} from '@angular/core';

@Directive({
  selector: '[appSelect]',
  exportAs: 'appSelect'
})
export class SelectDirective implements OnInit, OnDestroy, AfterContentInit {
  @Input('appSelect')
  public options: {icons: boolean;} = {icons: false};

  public instance: any;

  constructor(
    private elem: ElementRef,
    private zone: NgZone
  ) {}

  ngOnInit() {}

  ngAfterContentInit() {
    this.instance = M.FormSelect.init(this.elem.nativeElement);

    if(this.instance.el.classList.contains('browser-default')) return true;

    if(this.options.icons) {
      this.instance.el.addEventListener('change', (e) => this.updateIcon(e.target.selectedIndex));
      this.zone.runOutsideAngular(() => {
        setTimeout(() => this.updateIcon(this.instance.el.selectedIndex), 0);
      });
    }
    this.zone.runOutsideAngular(() => {
	console.log(this.instance);
    	setTimeout(() => this.instance._setValueToInput(), 0);
    });
  }

  ngOnDestroy() {
    if(this.instance.el.classList.contains('browser-default')) return true;
    this.instance.destroy();
  }

  private updateIcon(index: number): void {
    const option: HTMLElement | undefined = this.instance.el.options[index];

    if(option != null) {
      const icon: string | undefined = option.dataset.icon;

      this.instance.wrapper.classList.toggle('has-icon', icon != null);
      this.instance.input.style.backgroundImage = icon != null ? `url(${icon})` : '';
    }
  }
}
