import { Injectable } from '@angular/core';

import { AnyObject } from "../globals";

@Injectable({
  providedIn: 'root'
})
export class CameraService {
  private camera: any;

  private destinationType: any;

  private errMsg: string;

  private pictureSource: any;

  constructor() {
    this.camera = window.navigator.camera;
    this.errMsg = 'It seems your device does not support camera!';

    if(this.camera) {
      this.pictureSource = this.camera.PictureSourceType;
      this.destinationType = this.camera.DestinationType;
    }
  }

  private getPicture(options: AnyObject, base64:boolean = false): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if(this.camera) {
        this.camera.getPicture( 
          (imageData) => {
            base64 ? resolve(`data:image/png;base64,${imageData}`) : this.saveImage(imageData, resolve)
          },
          (message) => reject(message),
          options
        );
      }
      else {
        reject(this.errMsg);
      }
    });
  }

  private saveImage(
    imageData: string,
    onSuccess?: (filePath: string) => void,
    onError?: (msg: string) => void
  ): void {
    window.imageSaver.saveBase64Image({
      data: imageData,
      format: 'JPG',
      mediaScanner: true,
      prefix: 'myPrefix_',
      quality: 80
    },
    (filePath) => {
      if(onSuccess) {
        onSuccess(filePath);
      }
    },
    (msg) => {
      if(onError) {
        onError(msg);
      }
    });
  }

  public getPictureFromCamera(base64: boolean = false): Promise<string> {
    if(this.camera) {
      return this.getPicture({
        quality: 80,
		    allowEdit: false,
			targetHeight: 800,
			targetWidth: 600,
        destinationType: this.destinationType.DATA_URL
      }, base64);
    }
    else {
      return Promise.reject(this.errMsg);
    }
  }

  public getPictureFromGallery(base64: boolean = false): Promise<string> {
    if(this.camera) {
      return this.getPicture({
        quality: 80,
		    allowEdit: false,
			targetHeight: 800,
			targetWidth: 600,
        destinationType: this.destinationType.DATA_URL,
        sourceType: this.pictureSource.SAVEDPHOTOALBUM
      }, base64);
    }
    else {
      return Promise.reject(this.errMsg);
    }
  }
}
