import * as moment from 'moment';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { PermissionService } from '../../core/services/permission.service';
import { StorageService } from '../../core/services/storage.service';
import { UtilsService } from '../../core/services/utils.service';
import {
  FilterStructure,
  FilterType,
  FilterTypeObject,
} from '../../core/types/filter';
import {I18nService} from '@i18n';

@Component({
  selector: 'app-filters-form',
  templateUrl: './filters-form.component.html',
  styleUrls: ['./filters-form.component.scss'],
})
export class FiltersFormComponent implements OnInit {
  @Input()
  public filtersStructure: FilterStructure[] = [];

  @Input()
  public filtersData: any = {};

  @Output()
  public saved: EventEmitter<any> = new EventEmitter<any>();

  public typeLib: any = [];

  public tab: number = 0;

  public datePickerId: string = '';

  public formSelects: any;

  constructor(
    private permissionService: PermissionService,
    private storageService: StorageService,
    private host: ElementRef,
    public i18n: I18nService,
  ) {}

  ngOnInit() {
    this.typeLib = this.storageService.get('typeLib') || [];
    this.normalizeData();
  }

  ngAfterViewInit() {
    this.formSelects = this.host.nativeElement.querySelectorAll('select');
  }

  public updateSelectsValues(): void {
    if (this.formSelects) {
      this.formSelects.forEach((select: any) => {
        select.M_FormSelect._setValueToInput();
      });
    }
  }

  public getTabVisibility(type: FilterType): boolean {
    let visible: boolean = false;
    switch (type) {
      case 'object':
        visible = this.permissionService.hasPermission('can_add') || this.permissionService.hasPermission('can_edit');
        break;

      case 'inspection':
        visible = this.permissionService.hasPermission('can_control');
        break;

      case 'intervention':
        visible = this.permissionService.hasPermission('can_action');
        break;
    }

    return visible;
  }

  public switchTab(index: number): void {
    this.tab = index;
  }

  public normalizeData(): void {

    this.filtersStructure.forEach((item: FilterStructure) => {
      if (this.filtersData[item.type] === undefined) {
        this.filtersData[item.type] = {};
        item.objects.forEach((obj: FilterTypeObject) => {
          if (this.filtersData[item.type][obj.id_object_type] === undefined) {
            this.filtersData[item.type][obj.id_object_type] = {};
            this.filtersData[item.type][obj.id_object_type]['enable'] = 1;
          }
        });
      } else {
        item.objects.forEach((obj: FilterTypeObject) => {
          if (this.filtersData[item.type][obj.id_object_type] === undefined) {
            this.filtersData[item.type][obj.id_object_type] = {};
            this.filtersData[item.type][obj.id_object_type]['enable'] = 1;
          }
        });
      }
    });
  }

  public onSubmit(): void {
    this.saved.emit(this.filtersData);
  }

  private getTitle(id: string): string {
    let title: string = '';

    if (this.typeLib) {
      const typeLib: any = this.typeLib.find((type: any) => {
        return type.id === id;
      });
      title = typeLib ? typeLib.title : '';
    }
    if(!title) {
      return 'Sans titre';
    }
    return title;
  }

  public convertDate(date: Date): string {
    return date ? moment(date).format() : '';
  }

  public reset(): void {
    this.filtersData = {};
    this.onSubmit();
    this.normalizeData();
    setTimeout(() => {
      this.updateSelectsValues();
    }, 0);
  }
}
