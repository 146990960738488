import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { BaesFormData } from 'src/app/core/services/inspection';
import { BaesMapObject } from 'src/app/core/services/map-object';
import { StorageService } from 'src/app/core/services/storage.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { FormDataService } from '../../core/services/form-data.service';
import { RoutingHistoryService } from '../../routing-history/services';
import { NotificationService } from '../../core/services/notification.service';
import { CameraService } from '../../core/services/camera.service';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ImageTransferService } from '../../core/services/image-transfer.service';
import * as moment from 'moment';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import {I18nService}  from '@i18n';

@Component({
  selector: 'app-baes-inspection-form',
  templateUrl: './baes-inspection-form.component.html',
  styleUrls: ['./baes-inspection-form.component.scss']
})
export class BaesInspectionFormComponent implements OnInit {

  @Input()
  public object: BaesMapObject;

  public inputData?: BaesFormData;
  
  @Output()
  public submitted: EventEmitter<BaesFormData> = new EventEmitter<BaesFormData>();

  // public data: BaesFormData = new BaesFormData();
  public data: any = {};

  public title: string;

  public typeLib: any[] | undefined;

  public formStructure: any;

  public inspectionId: string;

  private imagesLimit: number;

  public images: string[] = [];

  public activeImageName: string;

  public activePictureName: string;
  public activePicture: string;

  public signName : string;
  public htmlName : string;

  public pdfName : string;

  public get imagesLimitReached(): boolean {
    return this.images.length >= this.imagesLimit;
  }

  public loading: boolean = false;

  public showModal: boolean = false;

  public typeLibId: string;

  public allFormStructure: any = [];

  public filteredData: string[] = [];

  public loadedFilteredData: boolean = false;



  constructor(
    private utils: UtilsService,
    private storageService: StorageService,
    private history: RoutingHistoryService,
    private formDataService: FormDataService,
    private notification: NotificationService,
    private camera: CameraService,
    private imageTransfer: ImageTransferService,
    private zone: NgZone,
    private sanitizer: DomSanitizer,
    public i18n: I18nService,
  ) {}

  ngOnInit() {

    if (this.inputData != null) {
      this.data = this.utils.extend({}, this.inputData);
    }

    this.typeLib = this.storageService.get('typeLib') || [];
    this.title = this.getTitle();
    this.inspectionId = this.getObjectTypeId();
    
    this.getFormStructure();
  }

  public getFormStructure(): void {
    if (this.object.id_object_type) {
	this.typeLibId=this.object.id_object_type;
	this.getStructure();
    this.inputData == null ? this.createDefaultValues() : null;
	this.createImagesData();
    }
    
  }


 public getStructure(){
    this.allFormStructure = this.storageService.get('allFormStructure');
    let formType = 'inspection';
    console.log('getStructure '+formType);
    if(!this.allFormStructure){
        this.allFormStructure = [];
    }
    if(this.allFormStructure && this.allFormStructure[formType] && this.allFormStructure[formType][this.typeLibId]){
	console.log('cache hit objectStructure inspection');
        this.formStructure = this.allFormStructure[formType][this.typeLibId];
    }
    else{
	console.log("Erreur: impossible de trouver le cache object structure inspection"); 
    }
 }



  public createImagesData(): void {   
    this.formStructure.forEach(el => {
        if (el.input_type === 'image' || el.input_type === 'picture') {//the same for sign
            this.data[el.name] =
                this.data[el.name] !== undefined ? this.data[el.name] : [];
            if(el.input_type === 'picture'){
                this.data[el.name] = el.placeholder;
                this.activePicture = el.placeholder;
            }
        }
        if(el.input_type === 'sign'){
            this.data[el.name] =
                this.data[el.name] !== undefined ? this.data[el.name] : '';
            this.signName = el.name;
        }
        if(el.input_type === 'pdf'){

            this.data[el.name] =
                el.placeholder;
            // this.data[el.name] !== undefined ? this.data[el.name] : '';
            this.pdfName = el.name;
        }
        if(el.input_type === 'html'){
            this.data[el.name] =
                el.placeholder.replace(/”/g, '');
            this.htmlName = el.name;
        }
    });
  }

  get sanitizedHtml() {
      return this.sanitizer.bypassSecurityTrustHtml(this.data[this.htmlName]);
  }

  public createCriterions(): void {
    this.data.criterions = [];
    this.formStructure.forEach(item => {
      if (item.input_type === 'status') {
        this.data.criterions.push({
          name: item.name,
          value: null,
        })
      }
    });
  }
 
  public onSubmit(): void {
	  console.log(this.data);
    this.data.created_at = moment().format('YYYY-MM-DD HH:mm:ss');
    this.data.email = this.storageService.get('userId');   
    this.submitted.emit(this.utils.extend({}, this.data));
  }

  private getTitle(): string {
    let title: string = '';
    
    if (this.typeLib) {
      const typeLibId: string | undefined = this.object.id_object_type;
      const typeLib: any = this.typeLib.find((type:any) => {
        return type.id === typeLibId
      })
      title = typeLib ? typeLib.title : '';
    }
    return title;  
  }

  private getInspectionID(): string {
    let id: string = '';
    
    if (this.typeLib) {
      const typeLibId: string | undefined = this.object.id_object_type;
      const typeLib: any = this.typeLib.find((type:any) => {
        return type.id === typeLibId
      })
      id = typeLib ? typeLib.id_inspection : '';
    }
    return id;   
  }

  private getObjectTypeId(): string {
   return this.object.id_object_type ? this.object.id_object_type : '';   
  }

  public onBack(): void {
    this.history.back();
  }

  public onSwitchChecked(state: boolean, name: string): void {
    this.data.criterions.forEach((criterion: any) => {
      if (criterion.name === name) {
        criterion.value = state; 
      }
    })
  }

  public addImage(imgSrc: string): void {
    this.zone.run(() => {
      this.data[this.activeImageName].push(imgSrc); 
    }); 
  }

  // public addImageFromCamera(): void {
  //   this.camera
  //     .getPictureFromCamera(true)
  //     .then((src) => this.addImage(src))
  //     .catch(window.alert);
  // }

  // public addImageFromGallery(): void {
  //   this.camera
  //     .getPictureFromGallery(true)
  //     .then((src) => this.addImage(src))
  //     .catch(window.alert);
  // }

  public addImageFromCamera(): void {
    this.mobileUpload('camera');
  }

  public addImageFromGallery(): void {
    this.mobileUpload('gallery');
  }

  private mobileUpload(source: string): void {
    const method: keyof CameraService =
      source === 'camera' ? 'getPictureFromCamera' : 'getPictureFromGallery';

    this.toggleLoader('show');

    from(this.camera[method]())
      .pipe(switchMap((filePath) => {
        return this.imageTransfer.uploadImage(filePath);
      }))
      .subscribe(
        (data) => {
          this.toggleLoader('hide');
          this.addImage(data)
        },
        (err: any) => {
          this.toggleLoader('hide');
          alert("Pas de connexion internet, impossible de télécharger l'image");
        }
      );
  }

  public removeImage(name: string, index: number): void {
    this.data[name].splice(index, 1); 
  }

  public toggleLoader(action:string): void {
    const loader: any = document.querySelector('.loader-section');

    if (loader) {
      loader.style.display = action === 'hide' ? 'none' : 'flex';
    }
  }

  public openExternal(loc: string): void{
      window.location.href =loc;
  }

  public toggleModal(): void{
      this.showModal =  this.showModal ? false : true;
  }

  public removePdf() : void{
      this.data[this.pdfName] = '';
  }

    public createDefaultValues(): void {
    this.formStructure.forEach((el) => {
      if (el.default_value) {
        this.data[el.name] = el.default_value;
      }
    });
  }

    public onChange(elem,event,tofilter){
        if(event.target.value.length<3) return false;
        var filtered = tofilter.filter(function(option) {
            return option.lib.includes(event.target.value);
        });
        this.filteredData[elem] = filtered;
    }

    public filter(elem,akey,tofilter){
        if (this.loadedFilteredData === false){
            var filtered = tofilter.filter(function(option) {
                    if(option.key==akey){
                    return option;
                    }
                    });
            this.filteredData[elem] = filtered;
            this.loadedFilteredData = true;
        }
    }

    public emptyFilter(event){
        event.target.value='';

    }


}
